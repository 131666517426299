<template>
	<div class="container">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'LayoutView',
	computed: {
	},
	watch: {
	},
	data() {
		return {
		}
	},
	methods: {
	},
}
</script> 

<!-- <style src="vue-multiselect/dist/vue-multiselect.css"></style> -->