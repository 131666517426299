import { createRouter, createWebHashHistory } from 'vue-router' 
import LayoutSurvey from '../../src/layouts/LayoutSurvey.vue'  
// import Survey from '../views/main/Survey.vue'
import SurveyTcc from '../views/main/SurveyTCC.vue'
import SurveyTcc_test from '../views/main/SurveyTCC_test.vue'
import store from '../store'

const routes = [ 
	{
		path: "/",
		name: "LayoutSurvey",
		component: LayoutSurvey,
		children:[
			{
				path: "tcc/:id",// 預約諮詢
				name: "surveyTcc",
				component: SurveyTcc,  
			}, 
			{
				path: "test/:id",// 預約諮詢
				name: "SurveyTcc_test",
				component: SurveyTcc_test,  
			}, 
		]
	}, 
	// {
	// 	path: "/test/:id",
	// 	name: "SurveyTcc_test",
	// 	component: SurveyTcc_test,
	// 	children:[
	// 		{
	// 			path: "",// 預約諮詢
	// 			name: "SurveyTcc_test",
	// 			component: SurveyTcc_test,  
	// 		}, 
	// 	]
	// }, 
	// {
	// 	path: "/survey/:id",
	// 	name: "LayoutSurvey",
	// 	component: LayoutSurvey,
	// 	children:[
	// 		{
	// 			path: "",// 預約諮詢
	// 			name: "survey",
	// 			component: Survey, 
	// 			// linkActiveClass: "active", 
	// 		}, 
	// 	]
	// }, 
];

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

// 假設有一個方法用於驗證TOKEN的有效性，可以根據您的需求進行修改
function isTokenValid() {
	// 在此處添加您的驗證TOKEN的邏輯，例如檢查TOKEN是否有效
	// 返回 true 或 false，表示TOKEN是否有效
	var userInfo = JSON.parse(localStorage.getItem('userInfo'));
	if (!userInfo) return false;
	store.state.userInfo = userInfo; 
	var token = userInfo.token || store.state.token;
	if (userInfo.token) store.commit('SetLoginToken',userInfo.token);

	if (token)
		return true;
	else
		return false;
}

router.beforeEach((to, from, next) => {
	if (to.matched.some(route => route.meta.requiresAuth)) {
		// 需要驗證TOKEN
		if (isTokenValid()) {
			// TOKEN有效，允許訪問該頁面 
			next();
		} else {
			// TOKEN無效，導向登入頁面 
			next('/');
		}
	} else {
		// 不需要驗證TOKEN，允許訪問該頁面
		next();
	}
})
export default router
