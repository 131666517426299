<template>
	<div class="popPanel" v-if="popPanel">
		<div class="popPanelBlock">
			<h3 class="text-center">中止原因</h3>
			<div>
				<input type="radio" name="popPanelValue" id="popPanelValue_reject" v-model="popPanelValue"
					value="reject">
				<label for="popPanelValue_reject"> (中止) 拒訪
				</label>
			</div>
			<div>
				<input type="radio" name="popPanelValue" id="popPanelValue_unqualified" v-model="popPanelValue"
					value="unqualified">
				<label for="popPanelValue_unqualified"> (中止) 不合格之受訪者
				</label>
			</div>
			<div>
				<input type="radio" name="popPanelValue" id="popPanelValue_backfill" v-model="popPanelValue"
					value="backfill">
				<label for="popPanelValue_backfill">
					(中止) 預約回訪
				</label>
			</div>
			<div class="text-center">
				<button class="previousPage btn" @click="popPanel = false"> 取消</button>
				<button class="nextPage btn " @click="getAnswer(popPanelValue)"> 結束表單</button>
			</div>
		</div>
	</div>
	<div v-if="surveyBasic && isLocationError" class="isLocationError">{{ locationMsg }}</div>
	<div class="survey row mt-0 p-0 " v-if="surveyBasic">
		<!-- <div class="col-12 p-0">
			<header>
				<nav class="navbar  ">
					<div>問卷</div>
					<div @click="popNav = !popNav" class="popNavBtn">≡</div>
					<div class="popNave" v-if="popNav">
						<div @click="createNew()">新建問卷</div>
						<div @click="popPanel = true">中止訪問</div>
						<div @click="goSystem">問卷紀錄</div>
					</div>
				</nav>
			</header>
		</div> -->
		<div class="col-12 p-0 ">
			<div class="survey-banner" style="background-image: url('./images/survey/tcc_banner.png');">
				<h1 v-if="surveyBasic.title">{{ surveyBasic.title }}</h1>
				<h1 v-if="!surveyBasic.title">文化幣藝文消費點調查</h1>
				<!-- <span class="serialNumber">問卷編號 : {{ surveyInfo.serial_number }}</span> -->
				<!-- <span class="locationMsg">位置 : {{ surveyInfo.filling_address }}</span> -->
			</div>
		</div>
	</div>
	<div class="survey row mt-0 p-0 " style=" 
		display: flex;
    align-items: center;
    align-content: center;"
		v-if="!listRespondent.list || listRespondent.list.length == 0 || listRespondent.list[0].status_id == surveyMode['done']">

		<div class="col-12 survey-form">
			<div class="survey-group p-2 p-md-2">
				<div class="survey-question">
					<div class="survey-question-content">
						<h3 class="isrequired">請輸入統編或身分證字號:</h3>
						<div class=" typeTagAlert" v-if="surveyInfo_alert.serial_number"> {{
		surveyInfo_alert.serial_number }}
						</div>
						<input type=" text" v-model="surveyInfo.serial_number">
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 text-center survey-form mb-5">
			<button class="nextPage btn" @click="
		this.GetListRespondent(this.surveyInfo.serial_number)" v-if="surveyInfo.serial_number"> 進入表單 </button>
			<div class="col-12 text-center survey-form mb-5"
				v-if="listRespondent && listRespondent.list && listRespondent.list.length == 0">
				查無店家，請重新請輸入統編或身分證字號
			</div>
			<div class="col-12 text-center survey-form mb-5"
				v-if="listRespondent && listRespondent.list && listRespondent.list.length != 0 && listRespondent.list[0].status_id == surveyMode['done']">
				您已完成表單，無需再填些！謝謝您
			</div>
		</div>


	</div>
	<div class="survey row mt-0 p-0 "
		v-if="surveyBasic && listRespondent && listRespondent.list && listRespondent.list.length != 0 && listRespondent.list[0].status_id != surveyMode['done']">
		<!-- <div class="col-12 p-0">
			<header>
				<nav class="navbar  ">
					<div>問卷</div>
					<div @click="popNav = !popNav" class="popNavBtn">≡</div>
					<div class="popNave" v-if="popNav">
						<div @click="createNew()">新建問卷</div>
						<div @click="popPanel = true">中止訪問</div>
						<div @click="goSystem">問卷紀錄</div>
					</div>
				</nav>
			</header>
		</div>
		<div class="col-12 p-0 ">
			<div class="survey-banner" style="background-image: url('./images/survey/survey_banner.jpg');">
				<h1>{{ surveyBasic.title }}</h1> 
			</div>
		</div> -->
		<div class="col-12 survey-form" v-if="!surveyDone && groupIndex == 1">
			<div class="survey-description p-3" v-html="surveyBasic.description"> </div>
		</div>
		<div class="col-12  survey-form" v-for="group in surveyBasic.groups" :key="group.id">
			<div class="survey-group p-2 p-md-4" v-if="isGroupIdExistGroupIndex(group.id, groupIndex) && !surveyDone">
				<!-- <h2 class="survey-group-content" v-if="group.content[0] != 'G'">{{ group.content }}</h2> -->
				<!-- survey-question -->
				<div class="survey-question" v-for="question in group.questions" :key="question.id"
					v-bind:qid="question.id"
					:class="{ 'survey-question-content-disable': processedSurveyBasic[group.id][question.id].isShow == false && !autoShow }">
					<div class="survey-question-title">
						{{ question.title }}
					</div>
					<div class="survey-question-content">
						<h3 class="isrequired"> {{ question.content }} </h3>
						<div v-if="question.id == 1006" class="my-2">
							<img src="../../assets/img/營業額.png" style="
    width: 100%;
    padding: 2%;">
						</div>
						<div v-if="question.id == 1005" class="my-2">
							<span style="
    color: rgb(255 47 31);
    background: #fff1f0;
    padding: 3px;
    border-radius: 5px;">＊ 不方便提供，可填寫 0 。</span>
						</div>

						<!-- 
							<div class="count-group">
								<div class="count">
									本年度營業額
									{{ processedSurveyBasic[25][1005].option[1756] +
		processedSurveyBasic[25][1005].option[1757] +
		processedSurveyBasic[25][1005].option[1758] +
		processedSurveyBasic[25][1005].option[1759] }}
								</div>
								{{}}
								<div class="count">
									計算結果 : &nbsp;&nbsp;
									<span
										v-if="processedSurveyBasic && processedSurveyBasic[26] && processedSurveyBasic[26][1006] && processedSurveyBasic[26][1006].option && processedSurveyBasic[26][1006].value != 0"
										class="count-result">{{
		(processedSurveyBasic[25][1005].option[1756] +
			processedSurveyBasic[25][1005].option[1757] +
			processedSurveyBasic[25][1005].option[1758] +
			processedSurveyBasic[25][1005].option[1759] -
			processedSurveyBasic[26][1006].value)
		/
		processedSurveyBasic[26][1006].value * 100 }}
										%</span>
								</div>
							</div>


						</div> -->
						<div v-if="question.id == 1007">
							<img src="../../assets/img/青年人數.png" style="
    width: 100%;
    padding: 2%;">
							<!-- <div class="count-group">
								<div class="count">
									本年度青年客群
									<input type="number" v-model="thisyear_youth">
								</div>
								<div class="count">
									去年度青年客群
									<input type="number" v-model="lastyear_youth">
								</div>
								<div class="count">
									計算結果 : &nbsp;&nbsp;
									<span v-if="lastyear_youth != 0" class="count-result">{{ (thisyear_youth -
		lastyear_youth) / lastyear_youth
										}} %</span>
								</div>
							</div> -->
						</div>
						<div class=" typeTagAlert"
							v-if="processedSurveyBasic[group.id][question.id].alert && processedSurveyBasic[group.id][question.id].isShow && !autoShow">
							{{ processedSurveyBasic[group.id][question.id].alert }}</div>
						<div class="typeTag" v-if="question.type_id == 2">複選題</div>
						<template v-for="option in  question.options.sort((a, b) => a.order - b.order)"
							:key="option.id">
							<!-- option-->
							<div v-if="question.type_id == 1" class="sform-radio">
								<input type="radio" :id="'option_id_' + option.id" :value="option.id"
									:name="'question_name_' + question.id"
									@change="jumpQuestion(group.id, question.id, $event)"
									:disabled="processedSurveyBasic[group.id][question.id].isShow == false && !autoShow"
									v-model="processedSurveyBasic[group.id][question.id].value">
								<label :for="'option_id_' + option.id">
									<span class="sform-title">({{ option.title }})</span>
									<FormInput
										:childProps="processedSurveyBasic[group.id][question.id].option[option.id]"
										:lableProps="option.content"
										@input-sth="processedSurveyBasic[group.id][question.id].option[option.id] = $event">
									</FormInput>
								</label>
							</div>

							<!-- option-->
							<div v-if="question.type_id == 2 && !(option.id == 1753 || option.id == 1754 || option.id == 1755)"
								class="sform-checkbox">
								<input type="checkbox" :id="'option_id_' + option.id" :value="option.id"
									:name="'question_name_' + question.id"
									@change="jumpQuestion(group.id, question.id, $event)"
									v-model="processedSurveyBasic[group.id][question.id].value"
									:disabled="processedSurveyBasic[group.id][question.id].isShow == false && !autoShow"
									v-bind:title="option.title">
								<label :for="'option_id_' + option.id">
									<span class="sform-title">({{ option.title }})</span>
									<FormInput
										:childProps="processedSurveyBasic[group.id][question.id].option[option.id]"
										:lableProps="option.content"
										@input-sth="processedSurveyBasic[group.id][question.id].option[option.id] = $event">
									</FormInput>
								</label>
							</div>
							<div v-if="question.type_id == 2 && (option.id == 1753 || option.id == 1754 || option.id == 1755)"
								class="sform-checkbox">
								<input type="checkbox" :id="'option_id_' + option.id" :value="option.id"
									:name="'question_name_' + question.id"
									@change="jumpQuestion(group.id, question.id, $event)"
									v-model="processedSurveyBasic[group.id][question.id].value"
									:disabled="option.id == 1753 || processedSurveyBasic[group.id][question.id].isShow == false && !autoShow"
									v-bind:title="option.title">

								<label :for="'option_id_' + option.id">
									<span class="sform-title">({{ option.title }})</span>
									<template v-for="(label, i) in option.content.split('__')" :key="i">
										<span v-if="label != 'BLANK' && label != 'NUMBER'">{{ label }}</span>

										<div v-if="label == 'BLANK'" class="input-block">
											<input type="text" @input="jumpQuestion(group.id, question.id, $event)"
												style="width:180px"
												v-model="processedSurveyBasic[group.id][question.id].option[option.id][0]"
												placeholder="請填入文字">

										</div>
										<br v-if="label == 'BLANK'">
										<div v-if="label == 'NUMBER'" style="width:100px" class="input-block">
											<input type="number" @onkeydown="isNumber($event)"
												@input="validateInput($event); jumpQuestion(group.id, question.id, $event)"
												v-model="processedSurveyBasic[group.id][question.id].option[option.id][1]"
												placeholder="請填數字">
										</div>
									</template>
								</label>
							</div>
							<!-- <div v-if="question.type_id == 2 && (option.id == 1756 || option.id == 1757 || option.id == 1758 || option.id == 1759)"
								class="sform-checkbox">
								<input type="checkbox" :id="'option_id_' + option.id" :value="option.id"
									:name="'question_name_' + question.id"
									@change="jumpQuestion(group.id, question.id, $event)"
									v-model="processedSurveyBasic[group.id][question.id].value"
									:disabled="true || processedSurveyBasic[group.id][question.id].isShow == false && !autoShow"
									v-bind:title="option.title">

								<label :for="'option_id_' + option.id">
									<span class="sform-title">({{ option.title }})</span>
									<template v-for="(label, i) in option.content.split('__')" :key="i">
										<span v-if="label != 'BLANK' && label != 'NUMBER'">{{ label }}</span>
										<div v-if="label == 'NUMBER'"
											@input="validateInput($event); jumpQuestion(group.id, question.id, $event)"
											class="input-block">
											<input type="number" @onkeydown="isNumber($event)"
												v-model="processedSurveyBasic[group.id][question.id].option[option.id]"
												placeholder="請填入數字">
										</div>
									</template>
								</label>
							</div>-->

						</template>

						<!-- option-->
						<input v-if="question.type_id == 3" type="text"
							@input="jumpQuestion(group.id, question.id, $event)"
							v-model="processedSurveyBasic[group.id][question.id].value"
							:disabled="processedSurveyBasic[group.id][question.id].isShow == false && !autoShow">
						<!--
							<input v-if="question.id == 1006" type="number" @onkeydown="isNumber($event)"
							@input="validateInput($event); jumpQuestion(group.id, question.id, $event)"
							placeholder="請填入前年度營業額" v-model="processedSurveyBasic[group.id][question.id].value"
							:disabled="processedSurveyBasic[group.id][question.id].isShow == false && !autoShow">
						-->
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 survey-form survey-form-bottom " v-if="!surveyDone">
			<button class="nextPage btn mb-5" @click="getAnswer('last')" v-if="groupIndex != 1"> 上一頁 </button>
			<!--  <button class="nextPage btn mb-5" @click="getAnswer('next')" v-if="next_groupIndex != null"> 下一頁 </button> -->
			<button class="nextPage btn mb-5" @click="getAnswer('done')"
				v-if="processedSurveyBasic && processedSurveyBasic[26] && processedSurveyBasic[26][1009].isShow && !isUnqualified">
				完成表單
			</button>
		</div>
		<div class="col-12 survey-form  survey-done" v-if="surveyDone">
			<h2>問卷填寫完成！</h2>
			<div>感謝您抽出寶貴的時間填寫我們的問卷</div>

			<!--   <button class="nextPage btn my-5" @click="createNew()">再次新建問卷</button>-->
		</div>
	</div>
</template>

<script>
import $ from 'jquery'
import { mapState, mapActions } from 'vuex';
import "../../assets/style/survey/basic.css";
import "../../assets/style/survey/main.css";
import FormInput from "../../components/survey/FormInput.vue";

export default {
	name: 'surveyTcc',
	components: {
		FormInput
	},
	computed: {
		...mapState(
			{
				searchSurvey: state => state.survey.search,
				createRespondent: state => state.respondent.create,
				saveRespondent: state => state.respondent.save,
				listRespondent: state => state.respondent.list,
				googleLocation: state => state.survey.googleLocation,
				googleGeocode: state => state.survey.googleGeocode,
			}
		),
	},
	watch: {
		searchSurvey(val) {
			console.log('searchSurvey')

			if (this.$route.query.serial_number) {
				this.surveyInfo.serial_number = this.$route.query.serial_number;
				this.GetListRespondent(this.$route.query.serial_number)
			}

			if (this.surveyType == 'new' && val && val.list && val.list[0]) {
				this.tidyData();
			}
			else if (this.surveyType == 'backfill' &&
				val && val.list && val.list[0] &&
				this.listRespondent && this.listRespondent.list && this.listRespondent.list[0]) {
				this.tidyData();
			} else
				console.log(this.listRespondent)
		},
		createRespondent(val) {
			console.log("Success")
			if (val.data.header.message == "Success") {
				this.surveyDone = true;
				this.popPanel = false;
				this.popNav = false;
				$('html, body').animate({ scrollTop: 0 }, 300);
			}
		},
		listRespondent(val) {
			console.log('listRespondent')
			if (val && val.list && val.list[0] &&
				this.searchSurvey && this.searchSurvey.list && this.searchSurvey.list[0]) {
				this.tidyData();
			}
		},
		googleGeocode(val) {
			this.surveyInfo.filling_address = val.slice(val.indexOf(' '));
		}
	},
	data() {
		return {
			thisyear_sum: 0,
			lastyear_sum: 0,
			thisyear_youth: 0,
			lastyear_youth: 0,


			//
			autoShow: false,//自動開啟
			popPanel: false,
			popPanelValue: 'backfill',
			popNav: false,

			isUnqualified: false,//不合格

			surveyType: '',
			locationMsg: '',
			isLocationError: false,
			surveyId: null,
			surveyDone: false,
			firstQuestionId: null,
			processedSurveyBasic: {},
			groupsId: [],
			questionsId: {},
			groupQuantity: 3, //單組數量
			groupIndex: 1, //第幾組
			next_groupId: 1,//下一個組數
			next_groupIndex: 1,//下一個組數 //next_groupIndex==null 代表結束><
			// history_groupIndex: [],//下一個組數
			answerData: {},//放gid option
			surveyInfo: {
				name: "",
				serial_number: "",
				date_of_birth: "",
				gender: "Unknown", // Male, Female, Unknown
				age: 0,
				phone_number: "",
				registered_address: "",
				residential_address: "",
				filling_ip_address: "",
				filling_address: "_",
				filling_start_time: "",
				filling_end_time: "",
				surveyor: "",
				response: [],
				status_id: 1,
			},
			surveyInfo_alert: {
				name: "",
				date_of_birth: "",
				gender: "", // Male, Female, Unknown
				age: "",
				phone_number: "",
				registered_address: "",
				residential_address: "",
				filling_ip_address: "",
				filling_address: "",
				filling_start_time: "",
				filling_end_time: "",
				surveyor: "",
			},
			jumpExceptionQid: {
				3: [1002],
			},
			//例外題目()
			showExceptionQid: {
				1: [],
				2: []
			},
			//例外顯示題目//當第n題題目選1,第n+1題就選
			checkExceptionQid: {
				// 1: [],
				1: [],
				2: []
			},
			surveyMode: { 'done': 1, 'reject': 2, 'unqualified': 3, 'backfill': 4 },
			surveyBasic: {},
			msg: '',
		}
	},
	mounted() {
		this.popNav = false;
		// //新增問卷
		// if (this.$route.query.surveyor_name) {
		// 	this.surveyType = 'new';
		// 	this.surveyInfo.surveyor = this.$route.query.surveyor_name;
		// }
		// //回填
		// else if (!isNaN(parseInt(this.$route.query.id))) {
		// 	this.surveyType = 'backfill';
		// 	this.GetListRespondent(parseInt(this.$route.query.id))
		// }
		if (this.$route.query.serial_number) {
			this.surveyInfo.serial_number = this.$route.query.serial_number;
			this.GetListRespondent(this.$route.query.serial_number)
		}

		this.GetSearchSurvey(this.$route.params.id);
		this.surveyId = this.$route.params.id;
		this.surveyInfo.filling_start_time = this.formattedDate();
	},
	methods: {
		...mapActions([
			'GetSearchSurvey',
			'GetCreateRespondent',
			'GetSaveRespondent',
			'GetGoogleGeocode',
			'GetListRespondent'
		]),
		goSystem: function () {
			location.href = this.$store.state.SYSTEM_URL;
		},
		createNew: function () {
			this.$router.push({
				path: '/survey/' + this.surveyId,
				replace: true,
				query: { 'surveyor_name': this.surveyInfo.surveyor }
			});
			if (this.surveyType == 'new') location.reload();
		},
		tidyData: function () {
			var self = this;
			var val = this.searchSurvey;
			var surveyBasic = val.list[0];
			// 資料處理--開始
			var processedSurveyBasic = {};
			var groupsId = [];
			var questionsId = {};

			surveyBasic.groups.forEach(_group => {
				processedSurveyBasic[_group.id] = {};
				groupsId.push(_group.id);
				questionsId[_group.id] = [];

				function stableSort(array, compareFunction) {
					const stabilized = array.map((el, index) => [el, index]);
					stabilized.sort((a, b) => {
						const order = compareFunction(a[0], b[0]);
						if (order !== 0) return order;
						return a[1] - b[1];
					});
					return stabilized.map(el => el[0]);
				}

				// 根据 order 属性对问题进行排序
				_group.questions = stableSort(_group.questions, (a, b) => a.order - b.order);

				_group.questions.forEach(_question => {
					processedSurveyBasic[_group.id][_question.id] = {
						isShow: surveyBasic.groups[0].questions[0].id == _question.id ? true : false,
						alert: '',
						value: _question.type_id == 2 ? [] : null,
						type_id: _question.type_id,
						msg: "",
						option: {},
						option_next_qid: {}
					}
					if (_question.id == 1004) { processedSurveyBasic[_group.id][_question.id].value = [1753] }
					// if (_question.id == 1005) { processedSurveyBasic[_group.id][_question.id].value = [1756, 1757, 1758, 1759] }
					if (_question.id == 1000 && self.listRespondent && self.listRespondent.list && self.listRespondent.list[0]) {
						processedSurveyBasic[_group.id][_question.id].value = self.listRespondent.list[0].serial_number;
						processedSurveyBasic[_group.id][_question.id].isShow = true;
					}
					if (_question.id == 1001 && self.listRespondent && self.listRespondent.list && self.listRespondent.list[0]) {
						processedSurveyBasic[_group.id][_question.id].value = self.listRespondent.list[0].name;
						processedSurveyBasic[_group.id][_question.id].isShow = true;
					}
					if (_question.id == 1002 && self.listRespondent && self.listRespondent.list && self.listRespondent.list[0]) {
						processedSurveyBasic[_group.id][_question.id].isShow = true;
					}

					questionsId[_group.id].push(_question.id);
					this.firstQuestionId = surveyBasic.groups[0].questions[0].id;

					_question.options.forEach(_option => {
						processedSurveyBasic[_group.id][_question.id].option[_option.id] = null;
						processedSurveyBasic[_group.id][_question.id].option_next_qid[_option.id] = _option.next_question_id;

						if (_option.id == 1753) processedSurveyBasic[_group.id][_question.id].option[_option.id] = [null, null]
						if (_option.id == 1754) processedSurveyBasic[_group.id][_question.id].option[_option.id] = [null, null]
						if (_option.id == 1755) processedSurveyBasic[_group.id][_question.id].option[_option.id] = [null, null]

						if (
							(_option.content.indexOf("__BLANK__") == -1) &&
							(_option.content.indexOf("__TEXTAREA__") == -1) &&
							(_option.content.indexOf("__NUMBER__") == -1) &&
							(_question.type_id != 3)
						) {
							processedSurveyBasic[_group.id][_question.id].option[_option.id] = _option.title;
						}
					});
				});
			});

			// 資料處理--結束 
			this.processedSurveyBasic = processedSurveyBasic;
			this.groupsId = groupsId;
			this.next_groupId = groupsId[0];
			this.next_groupIndex = groupsId[0];
			this.questionsId = questionsId;
			this.surveyBasic = surveyBasic;
			document.title = this.surveyBasic.title;

			if (this.surveyType == 'new') return;

			//接下來是回填時間
			var responses = this.listRespondent.list[0].responses;

			this.surveyInfo.filling_address = this.listRespondent.list[0].filling_address;
			this.surveyInfo.serial_number = this.listRespondent.list[0].serial_number;
			this.surveyInfo.surveyor = this.listRespondent.list[0].surveyor;
			this.surveyInfo.name = this.listRespondent.list[0].name;

			var optionWithContent = {};

			//整理成字典 oid : content
			responses.forEach(_r => {
				optionWithContent[_r.option_id] = _r.answer_content;
			})
			console.log(optionWithContent);
			this.answerData = {};

			//遍歷開始

			Object.keys(processedSurveyBasic).forEach(_g => {
				Object.keys(processedSurveyBasic[_g]).forEach(_q => {
					var _question = processedSurveyBasic[_g][_q];
					Object.keys(_question.option).forEach(_o => {
						if (optionWithContent[_o] != undefined) {
							console.log(_o + ' : ' + _question.option[_o]);

							//answerData 
							if (this.answerData[_g] == undefined) this.answerData[_g] = [];
							// if (optionWithContent[_o] != '__finale__') 
							this.answerData[_g].push(
								{
									"option_id": _o,
									"answer_content": optionWithContent[_o]
								}
							)

							processedSurveyBasic[_g][_q].isShow = true;

							if (optionWithContent[_o] == '__null__') {
								processedSurveyBasic[_g][_q].option[_o] = null;
							}
							else if (optionWithContent[_o] != '__finale__') {

								if (_question.type_id == 2) {
									processedSurveyBasic[_g][_q].option[_o] = JSON.parse(optionWithContent[_o]);
								} else {
									processedSurveyBasic[_g][_q].option[_o] = optionWithContent[_o];
								}

							}

							if (optionWithContent[_o] == '__finale__') {
								this.openTheQuestion(parseInt(_q), true);

							}
							else if (_question.type_id == 3) {
								processedSurveyBasic[_g][_q].value = optionWithContent[_o];
							}
							else if (_question.type_id == 2) {
								processedSurveyBasic[_g][_q].value.push(_o)
							}
							else {
								processedSurveyBasic[_g][_q].value = _o
							}

						}

					})
				})
			})

			// 資料處理--結束 
			this.processedSurveyBasic = processedSurveyBasic;

		},
		formattedDate: function () {
			const date = new Date();
			// 获取年、月、日、时、分、秒
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = date.getDate().toString().padStart(2, '0');
			const hours = date.getHours().toString().padStart(2, '0');
			const minutes = date.getMinutes().toString().padStart(2, '0');
			const seconds = date.getSeconds().toString().padStart(2, '0');

			// 格式化为 "YYYY-MM-DD HH:MM:SS" 格式
			const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

			return formattedDate;
		},
		isGroupIdExistGroupIndex: function (_groupId, _groupIndex) {//_groupId是否存在_groupIndex
			for (var i = 0; i < this.groupQuantity; i++) {
				if (this.groupsId[this.groupQuantity * (_groupIndex - 1) + i] == _groupId) {
					return true;
				}
			}
			return false;
		},
		jumpQuestion: function (gid, qid, e) {
			// var self = this;
			this.isUnqualified = false;
			if (e) {
				//互斥事件
				if ($(e)[0].target.type == 'checkbox') {
					var _thisOpitonInValue = this.processedSurveyBasic[gid][qid].value.indexOf(parseInt($(e)[0].target.value));
					$('[name=' + $(e)[0].target.name + ']').attr('disabled', false);
					if ($(e)[0].target.title == '90' && _thisOpitonInValue != -1) {
						$('[name=' + $(e)[0].target.name + ']').not($(e)[0].target).attr('disabled', 'disabled').prop('checked', false);
					} else if ($(e)[0].target.title == '91' && _thisOpitonInValue != -1) {
						$('[name=' + $(e)[0].target.name + ']').not($(e)[0].target).attr('disabled', 'disabled').prop('checked', false);
					} else if ($(e)[0].target.title == '92' && _thisOpitonInValue != -1) {
						$('[name=' + $(e)[0].target.name + ']').not($(e)[0].target).attr('disabled', 'disabled').prop('checked', false);
					} else if ($(e)[0].target.title == '96' && _thisOpitonInValue != -1) {
						$('[name=' + $(e)[0].target.name + ']').not($(e)[0].target).attr('disabled', 'disabled').prop('checked', false);
					} else if (this.processedSurveyBasic[gid][qid].value.length) {
						$('[name=' + $(e)[0].target.name + '][title=90]').attr('disabled', 'disabled').prop('checked', false);
						$('[name=' + $(e)[0].target.name + '][title=91]').attr('disabled', 'disabled').prop('checked', false);
						$('[name=' + $(e)[0].target.name + '][title=92]').attr('disabled', 'disabled').prop('checked', false);
						$('[name=' + $(e)[0].target.name + '][title=96]').attr('disabled', 'disabled').prop('checked', false);
					}
				}

				//不合格選項 
				// if ([1625, 1647, 4, 1669, 1691, 821].indexOf(parseInt($(e)[0].target.value)) != -1) {
				// 	self.isUnqualified = true;
				// 	self.popPanelValue = 'unqualified';
				// 	self.popPanel = true;
				// }
			}

			// 把這題之後的內容都清空 value=null , isShow=false;
			var findNowId = false;
			if (this.processedSurveyBasic[gid][qid].type_id != 3) {
				this.groupsId.forEach(_groupsId => {
					this.questionsId[_groupsId].forEach(_questionsId => {
						if (findNowId) {
							// this.answerData[_groupsId] = [];
							// this.processedSurveyBasic[_groupsId][_questionsId].value =
							// 	this.processedSurveyBasic[_groupsId][_questionsId].type_id == 2 ? [] : null;
							this.processedSurveyBasic[_groupsId][_questionsId].isShow = false;
						}
						if (_questionsId == qid) findNowId = true;
					})
				})
			}




			// 判斷下一題題目
			var nextQid;
			//跳題例外處理
			if (this.isJumpException(qid)) {
				nextQid = this.jumpException(qid);
			}
			else {
				switch (this.processedSurveyBasic[gid][qid].type_id) {
					case 1: //radio
						nextQid = this.processedSurveyBasic[gid][qid].option_next_qid[this.processedSurveyBasic[gid][qid].value];
						break;
					case 2: //checkbox
						if (this.processedSurveyBasic[gid][qid].value.length > 0)
							nextQid = this.processedSurveyBasic[gid][qid].option_next_qid[this.processedSurveyBasic[gid][qid].value[0]];
						break;
					case 3: //input 
						//找下一題
						nextQid = this.processedSurveyBasic[gid][qid].option_next_qid[Object.keys(this.processedSurveyBasic[gid][qid].option_next_qid)[0]]
						break;
				}
			}
			this.openTheQuestion(nextQid, false);
		},
		isJumpException: function (_qid) {
			if (this.jumpExceptionQid[this.surveyId].indexOf(_qid) != -1) return true;
			return false;
		},
		jumpException: function (_qid) {
			var nextQid;
			//問卷1,通訊
			if (this.surveyId == 3) {
				switch (_qid) {
					case 1002:
						//電子
						if (this.processedSurveyBasic[25][1002].value.indexOf(1742) != -1)
							nextQid = 1010;//電子
						else
							nextQid = 1003;
						break;
				}
			}
			return nextQid;
		},
		isShowException: function (_qid) {
			if (this.showExceptionQid.length && this.showExceptionQid[this.surveyId].indexOf(_qid) != -1) return true;
			return false;
		},
		showException: function (_qid) {
			if (this.surveyId == 1) {
				console.log(_qid)
			}
		},
		isCheckException: function (_qid) {
			if (this.checkExceptionQid.length && this.checkExceptionQid[this.surveyId].indexOf(_qid) != -1) return true;
			return false;
		},
		//失敗回傳false;
		checkException: function (_qid) {
			// var self = this;
			if (this.surveyId == 1) {
				console.log(_qid)
				return true;
			}
		},
		openTheQuestion: function (_qid, isJumpToPage) {
			if (_qid == null) {
				this.next_groupIndex = null;
				this.next_groupId = null;
			}
			this.groupsId.forEach(_gid => {
				if (this.questionsId[_gid].indexOf(_qid) != -1) {
					this.processedSurveyBasic[_gid][_qid].isShow = true;
					//下一題有被填寫 就遞迴
					console.log('就遞迴')
					if (this.processedSurveyBasic[_gid][_qid].value != null || (Array.isArray(this.processedSurveyBasic[_gid][_qid].value) && this.processedSurveyBasic[_gid][_qid].value.length != 0)) {

						console.log('就遞迴' + _gid + '... ' + _qid)
						this.jumpQuestion(_gid, _qid, null)
					}
					//下議題題組id
					this.next_groupId = _gid;
					this.next_groupIndex = Math.floor(this.groupsId.indexOf(_gid) / this.groupQuantity) + 1;

					//跳到當頁
					if (isJumpToPage) this.groupIndex = this.next_groupIndex;
				}
			})
			if (!this.isGroupIdExistGroupIndex(this.next_groupId, this.groupIndex)) {
				// console.log('要換頁囉！按鈕可顯示')
			}
			// 顯示例外isShowException
			if (this.isShowException(_qid)) {
				var self = this;
				setTimeout(function () {
					self.showException(_qid);
				}, 500)
			}
			this.getAnswer('autoSave');
		},
		getAnswer: function (mode) {
			var self = this;
			//基本資料
			var needFillUp = false;

			// 儲存當頁資料,並且檢查有沒有填寫！！ //後面都要存！！

			//this.groupIndex  跑報最後一個 this.groupIndex 

			for (var i = this.groupsId[this.groupQuantity * (this.groupIndex - 1)]; i <= Object.keys(this.questionsId)[Object.keys(this.questionsId).length - 1]; i++) {
				var thisGroupIndexRange = [this.groupsId[this.groupQuantity * (this.groupIndex - 1)], this.groupsId[this.groupQuantity * (this.groupIndex - 1) + this.groupQuantity - 1]]
				if (thisGroupIndexRange[1] == undefined) {
					thisGroupIndexRange[1] = this.groupsId[this.groupsId.length - 1]
				}
				console.log('thisGroupIndexRange' + thisGroupIndexRange)
				var _gid = i;

				this.answerData[_gid] = [];


				if (mode == "last") continue;

				//this.processedSurveyBasic[_gid] 便利gid 的 qid
				Object.keys(this.processedSurveyBasic[_gid]).forEach(_qid => {
					var _question = this.processedSurveyBasic[_gid][_qid];

					console.log('_question id ', _question.value)

					//backfill 的時候 還是需要存 option_id = __null__ 
					//需填寫題目。 
					if (_question.isShow &&
						((_question.type_id == 2 && _question.value.length == 0) || (_question.value == null))
					) {
						//用來告訴
						if (mode == "backfill" || mode == "autoSave" || mode == "unqualified" || mode == "reject") {
							this.answerData[_gid].push(
								{
									"option_id": parseInt(Object.keys(_question.option)[0]),
									"answer_content": "__finale__"
								}
							)
						}
						if ((mode != "backfill" && mode != "autoSave" && mode != "unqualified" && mode != "reject") && (_gid >= thisGroupIndexRange[0] && _gid <= thisGroupIndexRange[1])) {
							_question.alert = '此題必填';
							needFillUp = true;
						}
					}
					//有填寫題目 需填寫內容｜｜存資料
					else if (_question.isShow) {
						_question.alert = ''
						//input
						if ((_question.type_id == 3)) {

							this.answerData[_gid].push(
								{
									"option_id": parseInt(Object.keys(_question.option)[0]),
									"answer_content": _question.value != null ? _question.value : "__null__"
								}
							)
							// if (!_question.value != null && (mode != "backfill" && mode != "autoSave" && mode != "unqualified" && mode != "reject") && (_gid >= thisGroupIndexRange[0] && _gid <= thisGroupIndexRange[1])) {
							// 	_question.alert = '需填寫內容'
							// 	needFillUp = true;
							// }
						}
						//radio 
						else if ((_question.type_id == 1)) {

							this.answerData[_gid].push(
								{
									"option_id": _question.value,
									"answer_content": _question.option[_question.value] ? _question.option[_question.value] : "__null__"
								}
							)
							if (!_question.option[_question.value] && (mode != "backfill" && mode != "autoSave" && mode != "unqualified" && mode != "reject") && (_gid >= thisGroupIndexRange[0] && _gid <= thisGroupIndexRange[1])) {
								_question.alert = '需填寫內容'
								needFillUp = true;
							}
						}
						//checkbox
						//QQQQQQQ
						else if ((_question.type_id == 2)) {
							_question.value.forEach(_oid => {

								this.answerData[_gid].push(
									{
										"option_id": _oid,
										"answer_content": _question.option[_oid] ? JSON.stringify(_question.option[_oid]) : '__null__'
									}
								)
								if (!_question.option[_oid] && (mode != "backfill" && mode != "autoSave" && mode != "unqualified" && mode != "reject") && (_gid >= thisGroupIndexRange[0] && _gid <= thisGroupIndexRange[1])) {
									_question.alert = '需填寫內容'
									needFillUp = true;
								}

								if ((_oid == 1753 || _oid == 1754 || _oid == 1755) && (mode != "backfill" && mode != "autoSave" && mode != "unqualified" && mode != "reject") && (_gid >= thisGroupIndexRange[0] && _gid <= thisGroupIndexRange[1])) {
									if (_question.option[_oid].indexOf(null) != -1) {
										_question.alert = '需填寫內容'
										needFillUp = true;
									}

								}
							})
						}
					}
					//例外 
					if (_question.isShow && self.isCheckException(parseInt(_qid))) {
						needFillUp = !self.checkException(parseInt(_qid));


					}
				})
			}

			var _gIndex;
			var haveAnswerGroupsArr = Object.keys(this.answerData);
			var isGetPreGroupIndex, preGroupIndex;
			var isGetNextGroupIndex, nextGroupIndex;

			//last --> 回上一頁 
			if (mode == "last") {
				//找上一個
				isGetPreGroupIndex = false, preGroupIndex = 1;
				for (_gIndex = this.groupIndex - 1; _gIndex >= 1; _gIndex--) {
					haveAnswerGroupsArr.forEach(_answerGid => {
						if (this.isGroupIdExistGroupIndex(_answerGid, _gIndex)) {
							isGetPreGroupIndex = true;
							preGroupIndex = _gIndex;
						}
					})
					if (isGetPreGroupIndex) break;
				}
				//找下一個
				isGetNextGroupIndex = false, nextGroupIndex = this.groupIndex;
				for (_gIndex = preGroupIndex + 1; _gIndex <= Math.ceil(this.groupsId.length / this.groupQuantity); _gIndex++) {
					haveAnswerGroupsArr.forEach(_answerGid => {
						if (this.isGroupIdExistGroupIndex(_answerGid, _gIndex)) {
							isGetNextGroupIndex = true;
							nextGroupIndex = _gIndex;
						}
					})
					if (isGetNextGroupIndex) break;
				}
				console.log('preGroupIndex:' + preGroupIndex);
				console.log('nextGroupIndex:' + nextGroupIndex);
				this.next_groupIndex = nextGroupIndex;
				this.groupIndex = preGroupIndex;

				return;
			}
			//next --> 下一頁 
			else if (mode == "next") {
				//判斷是不是已經有填寫的下一頁？
				//從 answerData 看得出來 ：大於 next_groupIndex 的  
				isGetNextGroupIndex = false, nextGroupIndex = this.groupIndex;
				for (_gIndex = this.groupIndex + 1; _gIndex <= Math.ceil(this.groupsId.length / this.groupQuantity); _gIndex++) {
					haveAnswerGroupsArr.forEach(_answerGid => {
						if (this.isGroupIdExistGroupIndex(_answerGid, _gIndex)) {
							isGetNextGroupIndex = true;
							nextGroupIndex = _gIndex;
						}
					})
					if (isGetNextGroupIndex) break;
				}
				if (isGetNextGroupIndex) this.next_groupIndex = nextGroupIndex;
			}
			else if (mode == "backfill" || mode == "unqualified" || mode == "autoSave" || mode == "reject") needFillUp = false; //backfill 無需清理

			//必要
			// if (!this.surveyInfo.name) {
			// 	this.surveyInfo_alert.name = '此欄位必填'
			// 	needFillUp = true;
			// } else {
			// 	this.surveyInfo_alert.name = ''
			// }
			// if (!this.surveyInfo.surveyor) {
			// 	this.surveyInfo_alert.surveyor = '此欄位必填'
			// 	needFillUp = true;
			// } else {
			// 	this.surveyInfo_alert.surveyor = ''
			// }

			//needFillUp
			console.log('needFillUp' + needFillUp);
			if (!needFillUp) {
				if (this.next_groupIndex == null || mode == "backfill" || mode == "done" || mode == "autoSave" || mode == "unqualified" || mode == "reject") {
					//結束 
					this.saveSurvey(mode);
				} else {
					this.groupIndex = this.next_groupIndex;
					$('html, body').animate({ scrollTop: 0 }, 300);

					setTimeout(function () {
						// if (self.groupIndex == 2) self.showException(15);
					}, 500)
					this.getAnswer('autoSave');
				}
				//換頁了
			} else {
				//做一些跳轉到題目的動作,之後用
				setTimeout(function () {
					if ($('.typeTagAlert').length)
						$('html, body').animate({ scrollTop: $('.typeTagAlert')[0].closest('.survey-question').offsetTop }, 300);
				}, 500)

			}
		},
		previousPage: function () {
			// groupIndex  要返回 
		},
		saveSurvey: function (mode) {
			var arr = [];
			Object.keys(this.answerData).forEach(_gid => {
				this.answerData[_gid].forEach(_item => {
					arr.push(_item)
				})
			})
			this.surveyInfo.filling_end_time = this.formattedDate();
			this.surveyInfo.response = arr;
			if (mode == "autoSave") {
				this.surveyInfo.status_id = this.surveyMode['backfill'];
				this.GetSaveRespondent(this.surveyInfo);
			} else {
				this.surveyInfo.status_id = this.surveyMode[mode];
				this.GetCreateRespondent(this.surveyInfo);
			}
		},
		generateRandomString() {
			//this.$route.query.id
			//const letter = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
			const number = '0123456789';
			let result = '';
			// for (let i = 0; i < 3; i++) {
			// 	result += letter.charAt(Math.floor(Math.random() * letter.length));
			// }  

			if (this.$route.params.id == 1) {
				result = result + 'A';
			} else if (this.$route.params.id == 2) {
				result = result + 'B';
			}

			for (let i = 0; i < 5; i++) {
				result += number.charAt(Math.floor(Math.random() * number.length));
			}
			return result;
		},
		showPosition(position) {
			var latitude = position.coords.latitude;
			var longitude = position.coords.longitude;
			this.locationMsg = "Latitude: " + latitude + ", Longitude: " + longitude;
			this.GetGoogleGeocode(latitude + "," + longitude);
		},
		showError(error) {
			switch (error.code) {
				case error.PERMISSION_DENIED:
					this.isLocationError = true;
					this.locationMsg = "使用者拒絕了地理定位請求，請允許網站取得你的位置資訊。";
					break;
				case error.POSITION_UNAVAILABLE:
					this.isLocationError = true;
					this.locationMsg = "Location information is unavailable.";
					break;
				case error.TIMEOUT:
					this.isLocationError = true;
					this.locationMsg = "The request to get user location timed out.";
					break;
				case error.UNKNOWN_ERROR:
					this.isLocationError = true;
					this.locationMsg = "An unknown error occurred.";
					break;
			}
		},
		validateInput(event) {
			console.log("validateInput")
			const value = event.target.value;
			if (isNaN(value) || value === '') {
				event.target.value = ''; // 清空输入框 
			}
		},
		isNumber: function (evt) {
			console.log("isNumber!")
			evt = (evt) ? evt : window.event;
			var target = evt.target || evt.srcElement;
			var charCode = (evt.which) ? evt.which : evt.keyCode;

			// 检查是否是数字或小数点
			if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
				// 如果不是数字或小数点，则清空输入框
				if (target && target.value !== undefined) {
					target.value = '';
				}
				evt.preventDefault(); // 阻止默认行为
			} else {
				return true;
			}

		}
	},
}
</script>

<!--********** 
data包：
＊幾個group一組：groupQuantity  3
＊第幾組group：groupIndex 1
＊當頁group：groupNow[] = groupQuantity * (groupIndex-1)  ~ groupQuantity * (groupIndex-1) + groupQuantity - 1
＊AnswerStorage：
  下一頁,當頁的答案就可以存起來。
  上一頁,不動因為跳下一夜的時候,答案還會檢查並更新。 
  
【題目data】
綁定：input,checkbox,radio
data = {
  group_id:{
    question_id : {
      isShow : false,
      alert : '', 
      value : null || [],
      type_id : 1 || 2 || 3,
      msg:"",
      option : {
        option_id : null,
        option_id : null,
        option_id : null,
        option_id : null,
        option_id : null,
      },option_next_qid:null,
    }
  }
}

【選項data】
綁定：選項填寫之val
option_id : null 

【檢查是否填寫】
當
input被填寫||checkbox,radio被選擇
只檢查 groupNow 當下題組的題目
＊按下一頁面，即被存起來

【跳題】
當
input被填寫||checkbox,radio被選擇
進入 jumpQuestion(){

} 

【當某題題目選...就...】
inputEventFunction綁定：input,checkbox,radio
oninput = inputEventFunction(){
  去做節點的操作when nowQuestion.....
  【跳題】  
}

【撈資料模組】
資料整理：
answer={
	gid:[
		{
		"option_id": 1,
            "answer_content": "Answer Content API 01"
	}]
}
須從填寫當下開始製作。
x 每寫一題就 answer[i]=nowQuestion;  

按下換頁再檢查
把isShow的都存下來
並且檢查有沒有填寫！！
沒有填寫就顯示＊必填！！  
    "name": "Reina",
    "date_of_birth": "1997-12-25",
    "gender": "Female", // Male, Female, Unknown
    "age": 26,
    "phone_number": "0912345678",
    "registered_address": "Address01",
    "residential_address": "Address01",
    "filling_ip_address": "1.1.1.1",
    "filling_address": "Address01",
    "filling_start_time": "2024-02-17 03:43:26",
    "filling_end_time": "2024-02-17 03:43:26",
    "surveyor": "surveyor01",  
************-->