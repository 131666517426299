<template>
    <template v-if="!Array.isArray(childProps)">
        <template v-for="(label, i) in lableProps.split('__')" :key="i">
            <span v-if="label != 'BLANK' && label != 'NUMBER'">{{ label }}</span>
            <div v-if="label == 'BLANK'" class="input-block">
                <input type="text" :value="childProps" placeholder="請填入文字"
                    @input="$emit('input-sth', $event.target.value)">
            </div>
            <div v-if="label == 'NUMBER'" class="input-block">
                <input type="number" :value="childProps" placeholder="請填入數字" @onkeydown="isNumber($event)"
                    @input="validateInput">
            </div>
        </template>
    </template>
    <template v-if="Array.isArray(childProps)">
        //////////
        {{ childProps }}
        <template v-for="(label, i) in lableProps.split('__')" :key="i">
            {{ i }}
            <span v-if="label != 'BLANK' && label != 'NUMBER'">{{ label }}</span>

            <div v-if="label == 'BLANK' && i == 1" class="input-block">
                <input type="text" :value="childProps[0]" placeholder="請填入文字"
                    @input="$emit('input-sth', $event.target.value)">
            </div>

            <div v-if="label == 'NUMBER' || i == 3" class="input-block">
                <input type="number" :value="childProps[1]" placeholder="請填入數字" @onkeydown="isNumber($event)"
                    @input="validateInput">
            </div>
        </template>
    </template>

</template>

<script>
export default {
    props: ['childProps', 'lableProps'],
    emits: ['input-sth'],
    methods: {
        validateInput(event) {
            console.log('validateInput')
            const value = event.target.value;
            if (isNaN(value) || value === '') {
                event.target.value = ''; // 清空输入框
                this.$emit('input-sth', ''); // 发出空值事件
            } else {
                this.$emit('input-sth', value); // 发出输入值事件
            }
        },
        isNumber(evt) {
            console.log("isNumber!")
            evt = evt || window.event;
            const target = evt.target || evt.srcElement;
            const charCode = evt.which || evt.keyCode;

            // 检查是否是数字或小数点
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                // 如果不是数字或小数点，则清空输入框
                if (target && target.value !== undefined) {
                    target.value = '';
                }
                evt.preventDefault(); // 阻止默认行为
            } else {
                return true;
            }
        }
    }
}
</script>
